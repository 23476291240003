import { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Turnstile } from "@marsidev/react-turnstile";

import UserContext from "../../../contexts/user";
import CartContext from "../../../contexts/cart";

import StorefrontLoading from "../partials/loading";
import StorefrontError from "../partials/error";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faTrashCan, faBasketShopping, faShoppingCart, faDollarSign, faStar } from "@fortawesome/free-solid-svg-icons";

import { API_URL, CLOUDFLARE_TURNSTILE_CHECKOUT_SITEKEY } from "../../../config";

const logos = {
    "Cryptocurrency": faCoins,
    "Cashapp": faDollarSign,
    "TelegramStars": faStar
};

const CartItem = ({ updateCart, product_id, image, name, price_per_item, min_quantity, quantity, updateCartPage }) => {
    const [loading, setLoading] = useState(false);
    const setQuantity = async(new_quantity) => {
        setLoading(true);
        if(new_quantity < quantity) {
            const data = await fetch(`${API_URL}/cart/remove`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    item_id: product_id,
                    quantity: quantity - new_quantity
                }),
                credentials: "include"
            }).then(r => r.json());
            setLoading(false);
            if(data.success) {
                updateCartPage(); 
                updateCart();
            }
        } else if(new_quantity > quantity) {
            try {
                const data = await fetch(`${API_URL}/cart/add`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        item_id: product_id,
                        quantity: new_quantity - quantity
                    }),
                    credentials: "include"
                }).then(r => r.json());
                setLoading(false);
                if(data.success) {
                    updateCartPage(); 
                    updateCart();
                }
            } catch(e) {

            };
        };
    };

    const RemoveItem = async() => {
        setLoading(true);
        try {
            const data = await fetch(`${API_URL}/cart/remove`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    item_id: product_id,
                    quantity: quantity
                }),
                credentials: "include"
            }).then(r => r.json());
            setLoading(false);
            if(data.success) {
                updateCartPage();
                updateCart();
            }
        } catch(e) {

        };
    };

    if(loading) return (
        <div className="storefront-cart-item">
            <StorefrontLoading />
        </div>
    );

    return (
        <div className="storefront-cart-item">
            <div className="storefront-cart-item-title">
                <div className="storefront-cart-content-title">{name}</div>
                <div className="storefront-quantity-select">
                    <div className="storefront-quantity-control storefront-cart-quality-control">
                        <button className="btn storefront-quantity-btn storefront-cart-quantity-btn" onClick={() => setQuantity(quantity - 1)}>-</button>
                        <input type="text" value={quantity} onChange={(e) => setQuantity(e.target.value)} min={min_quantity} disabled />
                        <button className="btn storefront-quantity-btn storefront-cart-quantity-btn" onClick={() => setQuantity(quantity + 1)}>+</button>
                    </div>
                </div>
                <button className="btn btn-snb storefront-cart-remove-btn" onClick={() => RemoveItem()}><FontAwesomeIcon icon={faTrashCan} /></button>
            </div>
            <div className="storefront-cart-item-content">
                <img className="storefront-cart-image" src={image} alt="Item icon" />
                <div className="storefront-cart-item-options">
                    <div className="storefront-cart-content-quantity">${price_per_item.toFixed(2)} x {quantity}</div>
                    <div className="storefront-cart-content-price">${(price_per_item * quantity).toFixed(2)}</div>
                </div>
            </div>
        </div>
    )
};

const Cart = ({ updateCart }) => {
    const { user } = useContext(UserContext);
    const { setCart: setCartContext } = useContext(CartContext);
    const guestCheckout = !(user !== null && user.loggedin !== false);

    const [cart, setCart] = useState([]);
    const [cartTotal, setCartTotal] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [error, setError] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [guestEmail, setGuestEmail] = useState(null);
    const [useAccountBalance, setUserAccountBalance] = useState(true);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const navigate = useNavigate();

    const updateCartPage = async() => {
        try {
            const data = await fetch(`${API_URL}/cart`, {
                method: "GET",
                credentials: "include"
            }).then(r => r.json());
            if(!data.success) {
                setError(data.response);
                return;
            }
            setCart(data.cart);
            setCartTotal(data.cart_total);
            setCartContext(data.cart.map(c => {
                return {
                    product_id: c.product_id,
                    quantity: c.quantity,
                    price_per_item: c.price_per_item
                };
            }));

            const paymentMethodsData = await fetch(`${API_URL}/checkout/paymentmethods`, {
                method: "GET",
                credentials: "include"
            }).then(r => r.json());
            setPaymentMethods(paymentMethodsData.payment_methods);

            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred while fetching your cart, please try again later or contact support if this error persists");
        };
    };

    useEffect(() => {
        updateCartPage();
    }, []);

    const checkout = async() => {
        setLoaded(false);
        try {
            let guest_email = guestCheckout ? guestEmail : user.email;
            let payment_method = paymentMethod;
            let use_account_balance = useAccountBalance;

            const data = await fetch(`${API_URL}/checkout`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    guest_email,
                    payment_method,
                    use_account_balance
                }),
                credentials: "include"
            }).then(r => r.json());

            if(!data.success) {
                setLoaded(true);
                setError(data.response);
                return;
            };

            if(data.payment_already_settled === true) {
                navigate(`/user/orders/${data.order_id}`);
            } else {
                navigate(data.checkout_url);
            };
        } catch(e) {
            setLoaded(true);
            console.log(e);
            setError("An unknown error occurred while creating your checkout session, please try again later or contact support if this error persists");
        }
    };

    if(error !== false) return <StorefrontError error={error} />;
    if(loaded === false) return <StorefrontLoading />;
	
	
    return (
        <div className="storefront-cart-pg">
            <div className="storefront-cart">
                <div className="storefront-cart-title">
                    <div className="sft-title">Your Cart</div>
                    <div className="sft-products">{cart.length} {cart.length !== 1 ? "products" : "product"}</div>
                </div>
                {cart.length > 0 
                ? <>
                    {cart.map((cartItem, index) => {
                        return <CartItem key={`cartitem-${index}-${Math.random.toString()}`} updateCart={updateCart} product_id={cartItem.product_id} image={cartItem.image} name={cartItem.name} price_per_item={cartItem.price_per_item} min_quantity={cartItem.min_quantity} quantity={cartItem.quantity} updateCartPage={() => updateCartPage()} />;
                    })}
                </>
                : <div className="cart-empty">
                    <div className="storefront-error">
                        <div className="cart-empty-icon"><FontAwesomeIcon icon={faBasketShopping} /></div>
                        <div className="cart-empty-text">Your cart is empty!</div>
                        <button className="btn btn-nrm btn-nrm-sm mt-2" onClick={() => navigate("/")}><FontAwesomeIcon icon={faShoppingCart} />Go shopping</button>
                    </div>
                </div>}
            </div>
            <div className="storefront-checkout">
                <div className="storefront-checkout-title">Checkout Options</div>

                <div className="checkout-inputs">
                    <div className="input-group storefront-checkout-payment-methods">
                        <label>Payment Method</label>
                        {paymentMethods.map((payment_method, index) => {
                            return (
                                <div key={`cart-payment-method-${index}-${Math.random.toString()}`} className={`storefront-checkout-payment-method ${paymentMethod === payment_method.id ? "storefront-checkout-payment-method-selected" : ""}`} onClick={() => setPaymentMethod(payment_method.id)}>
                                    <div className="storefront-checkout-payment-method-content">
                                        <div className="storefront-checkout-payment-method-title">{payment_method.name}</div>
                                    </div>
                                    <FontAwesomeIcon icon={logos[payment_method.name]} className="storefront-checkout-payment-method-image" />
                                </div>
                            )
                        })}
                    </div>

                    {guestCheckout ?
                        <div className="input-group">
                            <label>Guest Checkout</label>
                            <input className="auth-form-input checkout-input" value={guestCheckout === false ? user.email : guestEmail} onChange={(e) => setGuestEmail(e.target.value)} type="text" placeholder="Enter your email address" disabled={!guestCheckout} />
                        </div>
                    : null}
                </div>

                {!guestCheckout
                ? 
                <div className="storefront-checkout-use-account-balance">
                    <label>Use account balance <b>(${user.balance.toFixed(2)})</b></label>
                    <input type="checkbox" defaultChecked={useAccountBalance} onChange={(e) => setUserAccountBalance(e.target.checked)} />
                </div>
                : null
                }

                <div className="checkout-summary">
                    <div className="checkout-summary-content">
                        <div className="checkout-summary-text">
                            <div>Subtotal</div>
                            <div>${cartTotal.toFixed(2)}</div>
                        </div>
                        {discountAmount > 0
                        ? <div className="checkout-summary-text">
                            <div>Discount</div>
                            <div>${discountAmount.toFixed(2)}</div>
                        </div> : null}
                        {useAccountBalance && !guestCheckout
                        ? <div className="checkout-summary-text">
                            <div>Account Balance</div>
                            <div>${user.balance.toFixed(2)}</div>
                        </div> : null}
                    </div>
                    <div className="checkout-total-content">
                        <div className="checkout-total-title">Total</div>
						<div className="checkout-total-amount">${useAccountBalance && !guestCheckout ? ((cartTotal - user.balance) <= 0 ? (0).toFixed(2) : (cartTotal - user.balance).toFixed(2)) : cartTotal.toFixed(2) }</div>
                    </div>
                </div>

                <div className="checkout-complete">
                    <button className="btn auth-form-btn" disabled={!(paymentMethod !== null && cart.length > 0) || !(guestCheckout === false || (guestEmail !== null && guestEmail.length > 0))} onClick={() => checkout()}>{guestCheckout ? "Continue as guest" : "Continue"}</button>
                </div>
            </div>
        </div>
    )
};

export default Cart;