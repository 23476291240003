import { useContext, useEffect, useState, useRef } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { Turnstile } from "@marsidev/react-turnstile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faXmark, faBars, faWallet, faUser, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";

import CartContext from "../../../contexts/cart";

import { API_URL, CLOUDFLARE_TURNSTILE_CHECKOUT_SITEKEY, TELEGRAM_CHANNEL_URL, LOGO_URL } from "../../../config";

Modal.setAppElement("#root");

const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '9999',
        padding: '0'
    },
    overlay: {
        zIndex: "9999"
    }
};

const CartButton = ({ cart }) => {
    const [cartTotalItems, setCartTotalItems] = useState(0);
    const [cartTotalPrice, setCartTotalPrice] = useState(0);

    useEffect(() => {
        if(!(cart.length > 0)) return;
        setCartTotalItems(cart.reduce((total, product) => {
            return total + product.quantity;
        }, 0));
        setCartTotalPrice(cart.reduce((total, product) => {
            return total + (product.quantity * product.price_per_item);
        }, 0));
    }, [cart]);

    return <Link to ="/cart">
        <button className="btn btn-nav btn-snb btn-nav-cart">
            <FontAwesomeIcon icon={faShoppingCart} />
            ${cartTotalPrice.toFixed(2)}
            {/* {cartTotalItems} {cartTotalItems === 1 ? "item" : "items"} (${cartTotalPrice.toFixed(2)}) */}
        </button>
    </Link>
};

const BalanceTopupModal = ({ balanceModalOpen, setBalanceModalOpen }) => {
    const [balanceTopupAmount, setBalanceTopupAmount] = useState(false);
    const [balanceTopupPaymentMethod, setBalanceTopupPaymentMethod] = useState("cryptocurrency");
    const [loaded, setLoaded] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const submit = async() => {
        setLoaded(false);
        try {
            const data = await fetch(`${API_URL}/checkout/balance`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    amount: balanceTopupAmount,
                    payment_method: balanceTopupPaymentMethod
                }),
                credentials: "include"
            }).then(r => r.json());
            if(!data.success) {
                setLoaded(true);
                setError(data.response);
                return;
            };

            setBalanceModalOpen(false);
            navigate(data.checkout_url);
        } catch(e) {
            setLoaded(true);
            console.log(e);
            setError("An unknown error occurred while creating your checkout session, please try again later or contact support if this error persists");
        }
    };

    return (
        <Modal
            isOpen={balanceModalOpen}
            onRequestClose={() => setBalanceModalOpen(false)}
            style={modalStyle}
        >
            {error !== null ? <div className="auth-form-error">{error}</div> : null}

            <div className="storefront-modal-title">Top up balance</div>

            <div className="storefront-modal-content">
                <div className="input-group mt-2">
                    <label>Amount</label>
                    <input type="number" className="auth-form-input secondary-dark-bg" defaultValue={balanceTopupAmount} onChange={(e) => setBalanceTopupAmount(e.target.value)} />
                </div>

                <div className="input-group mt-2">
                    <label>Payment Method</label>
                    <select className="auth-form-input secondary-dark-bg" defaultValue={balanceTopupPaymentMethod} onChange={(e) => setBalanceTopupPaymentMethod(e.target.value)}>
                        <option value="cryptocurrency">Cryptocurrency</option>
						<option value="cashapp">Cashapp</option>
                        <option value="telegramstars">Telegram Stars</option>
                    </select>
                </div>

                <button className="btn auth-form-btn storefront-modal-btn" disabled={ loaded === false} onClick={() => submit()}>{loaded === true ? "Top-Up Balance" : "Loading..."}</button>
            </div>
        </Modal>
    )
};

const Navbar = ({ user }) => {
    const [balanceModalOpen, setBalanceModalOpenState] = useState(false);
    const { cart } = useContext(CartContext);
    const navigate = useNavigate();

    const setBalanceModalOpen = (value) => {
        if(value === true) {
            document.body.setAttribute("style", "overflow: hidden;");
        } else {
            document.body.setAttribute("style", "");
        };
        setBalanceModalOpenState(value);
    };

    const [navbarToggled, setNavbarToggled] = useState(false);

    return (
        <>
            <div className="storefront-navbar">
                <div className="storefront-navbar-branding">
                    <img className="storefront-navbar-branding-img" src={LOGO_URL} alt="site logo" onClick={() => navigate("/")} />
                </div>
                <div className="storefront-navbar-options">
					<NavLink to="https://t.me/TopAltsSupport_Bot" className={({ isActive }) => `storefront-navbar-option ${isActive ? "storefront-navbar-option-active" : ""}`}>Support</NavLink>
                    <NavLink to="/" className={({ isActive }) => `storefront-navbar-option ${isActive ? "storefront-navbar-option-active" : ""}`}>Shop</NavLink>
                    <NavLink to="/faq" className={({ isActive }) => `storefront-navbar-option ${isActive ? "storefront-navbar-option-active" : ""}`}>FAQ</NavLink>
                    <NavLink to={TELEGRAM_CHANNEL_URL} target="_blank" rel="noopener noreferrer" className={({ isActive }) => `storefront-navbar-option sno-image ${isActive ? "storefront-navbar-option-active" : ""}`}>
                        Reviews
                        <img src={"https://i.imgur.com/y1PYC6t.png"} />
                    </NavLink>
                </div>
                {user !== null && user.loggedin === false
                ?
                    <div className="storefront-navbar-right">
                        <CartButton cart={cart} />
                        <Link to="/user/login"><button className="btn btn-snb btn-nav btn-nav-secondary">Login</button></Link>
                        <Link to="/user/register"><button className="btn btn-nav btn-snb">Sign Up</button></Link>
                    </div>
                :   
                    <div className="storefront-navbar-right">
                        <CartButton cart={cart} />
                        <button className="btn btn-snb btn-nav btn-snb-outline" onClick={() => setBalanceModalOpen(true)}><FontAwesomeIcon icon={faWallet} />${user.balance.toFixed(2)}</button>
                        <Link to="/user/home"><button className="btn btn-nav btn-snb"><FontAwesomeIcon icon={faUser} />{user.username}</button></Link>
                    </div>
                }
                <div className="storefront-navbar-mobile-toggle" onClick={() => setNavbarToggled(!navbarToggled)}>
                    <FontAwesomeIcon icon={navbarToggled ? faXmark : faBars} />
                </div>
            </div>





            {navbarToggled
            ?
            <div className="storefront-navbar-mobile">
                {user !== null && user.loggedin === false
                ?
                    <div className="storefront-navbar-mobile-content">
                        <Link to="/user/login"><button className="btn btn-snb btn-nav btn-snb-outline">Login</button></Link>
                        <Link to="/user/register"><button className="btn btn-nav btn-snb">Sign Up</button></Link>
                        <CartButton cart={cart} />
                    </div>
                :   
                    <div className="storefront-navbar-mobile-content">
                        <Link to="/user/logout"><button className="btn btn-snb btn-nav btn-snb-outline-none">Logout</button></Link>
                        <button className="btn btn-snb btn-nav btn-snb-outline" onClick={() => setBalanceModalOpen(true)}>${user.balance} Balance</button>
                        <Link to="/user/home"><button className="btn btn-nav btn-snb">Welcome, {user.username}</button></Link>
                        <CartButton cart={cart} />
                    </div>
                }
            </div>
            : null}
            <BalanceTopupModal balanceModalOpen={balanceModalOpen} setBalanceModalOpen={setBalanceModalOpen} />
        </>
    )
};

export default Navbar;